import {useRouter} from "next/router";
import {useContext} from "react";
import ButterCMSContentContext from "../../components/context/butterCMSContent";

const defaultContent = {
  collection_name: "default",
  app_download_links: [
    {
      source: "default",
      url: "https://autonomy.app.link/sign-in",
      qr_code: "https://cdn.buttercms.com/kqsJ3JKTBOOSaIEiRucl",
      qr_code_link: "",
    },
    {
      source: "carscom",
      url: "https://autonomy.app.link/TiehLsXBKqb",
      qr_code: "https://cdn.buttercms.com/QA2cfvP0RVWU8PkA4giU",
      qr_code_link: "",
    },
    {
      source: "autotrader.com",
      url: "https://autonomy.app.link/j9vMraEygrb",
      qr_code: "https://cdn.buttercms.com/WQ1o9JTS6NBRHDMyy0SA",
      qr_code_link: "",
    },
    {
      source: "perksatwork",
      url: "https://autonomy.app.link/SKBfuyu4dsb",
      qr_code: "https://cdn.buttercms.com/RzOFeuioS4GcwVeRirEx",
      qr_code_link: "",
    },
    {
      source: "jdpower",
      url: "https://autonomy.app.link/PnpD5Iidrsb",
      qr_code: "https://cdn.buttercms.com/YLDdjZNwTnJ1CZp6uSiU",
      qr_code_link: "",
    },
    {
      source: "nadaguide",
      url: "https://autonomy.app.link/e/EGn4ILfdrsb",
      qr_code: "https://cdn.buttercms.com/Q3Jb5SrTSK6fNmLVNYuX",
      qr_code_link: "",
    },
    {
      source: "truecar",
      url: "https://autonomy.app.link/prDxoEddrsb",
      qr_code: "https://cdn.buttercms.com/x4QPRWOLQs6Ky1Zf8sta",
      qr_code_link: "",
    },
  ],
};

export default function useAppDownload() {
  let {query: {utm_source} = {}} = useRouter();
  const {common: {content = defaultContent} = {}} = useContext(
    ButterCMSContentContext
  );

  const {app_download_links} = content;

  if (utm_source) {
    utm_source = utm_source.toLowerCase();
  }

  const appDownloadLink =
    app_download_links.find(({source}) => source === utm_source) ||
    app_download_links[0];

  return {
    appDownloadLink,
  };
}
