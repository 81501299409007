let result_url = "";

export function generateOneLinkURL() {
  if (result_url) {
    return result_url;
  }

  //Initializing Smart Script arguments
  const oneLinkURL = "https://get.autonomy.com/q7xl/";
  // If both media source keys are NOT FOUND on the link and NO default value is found, the script will return a null string
  const mediaSource = {
    keys: ["utm_source"],
    defaultValue: "autonomy_default_source",
  };
  const campaign = {
    keys: ["utm_campaign"],
  };
  const channel = {
    keys: ["utm_medium"],
  };
  const ad = {keys: ["utm_adname"]};
  const adSet = {
    keys: ["utm_adsetname", "utm_adgroupname"],
  };
  const afSub5 = {
    keys: ["fbclid", "gclid"],
  };
  const af_campaignId = {
    paramKey: "af_campaignId",
    keys: ["utm_cpn", "utm_cid"],
  };
  const af_adSetId = {
    paramKey: "af_adSetId",
    keys: ["utm_adgrp", "utm_sid"],
  };
  const af_placement = {
    paramKey: "af_placement",
    keys: ["utm_placement"],
  };
  const af_adId = {
    paramKey: "af_ad_id",
    keys: ["utm_aid"],
  };
  const af_searchKeywords = {
    paramKey: "af_searchKeywords",
    keys: ["utm_term"],
  };

  //Calling the function after embedding the code will be through a global parameter on the window object called window.AF_SMART_SCRIPT
  //Onelink URL is generated
  var result = window.AF_SMART_SCRIPT.generateOneLinkURL({
    oneLinkURL,
    afParameters: {
      mediaSource,
      campaign,
      channel,
      ad,
      adSet,
      afSub5,
      afCustom: [
        af_campaignId,
        af_adSetId,
        af_placement,
        af_adId,
        af_searchKeywords,
      ],
    },
  });

  result_url = "No output from script";

  if (result) {
    result_url = result.clickURL;
  }

  return result_url;
}
