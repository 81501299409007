import lodashTemplate from "lodash/template";

const interpolate = /{{([\s\S]+?)}}/g;
export const template = (textContent) => {
  const compiled = lodashTemplate(textContent, {interpolate});

  return (data = {}) => {
    let replacedTextContent = textContent;

    try {
      replacedTextContent = compiled(data);
    } catch (err) {
      console.info("Given text content:", textContent);
      console.log(err?.message || "");
    }

    return replacedTextContent;
  };
};

export function flattenFieldsByType(fields) {
  return (fields || []).reduce(
    (acc, current) => ({
      ...acc,
      [current.type]: current.fields,
    }),
    {}
  );
}

export function flattenPageContent(pageContent) {
  const {fields, ...restPageContent} = pageContent || {};
  const {body, ...rest} = pageContent?.fields || {};
  return {...restPageContent, ...flattenFieldsByType(body), ...rest};
}

export function processImageWithFilestack(butterCMSImageUrl, options) {
  const originalUrl = new URL(butterCMSImageUrl);
  let filestackOptions = "";

  for (const key in options) {
    filestackOptions += `/${key}=${options[key]}`;
  }

  return originalUrl.origin + filestackOptions + originalUrl.pathname;
}
