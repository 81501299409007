import React, {useEffect} from "react";
import {generateOneLinkURL} from "../../../lib/appsflyerOneLink";
import styles from "./AppQRCode.module.scss";

export default function AppQRCode({id}) {
  useEffect(() => {
    async function generateQRCode() {
      if (window.AF_SMART_SCRIPT) {
        // TODO - only done once in the app
        await generateOneLinkURL();
        window.AF_SMART_SCRIPT.displayQrCode(id);
      }
    }

    generateQRCode();
  }, []);

  return (
    <div id={id} className={styles.qrWrap}>
      {/* Appsflyer QR injected here */}
      <div className={styles.qrBorder}>
        <div className={styles.qrTop}></div>
        <div className={styles.qrLeft}></div>
        <div className={styles.qrBot}></div>
        <div className={styles.qrRight}></div>
      </div>
    </div>
  );
}
