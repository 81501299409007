import Head from "next/head";
import {useRouter} from "next/router";
import React, {useEffect, useMemo, useState} from "react";
import {flattenPageContent} from "../../lib/butter-utils";

const isProduction = process.env.NODE_ENV !== "development";

const ButterCMSContentContext = React.createContext({});

export function ButterCMSContentContextProvider({pageContent, children}) {
  const [isMount, setIsMount] = useState(false);
  const {pathname} = useRouter();
  const {seo, ...newPageContent} = useMemo(
    () => flattenPageContent(pageContent),
    [pageContent]
  );

  useEffect(() => {
    setIsMount(true);
  }, []);

  if (!isMount) {
    return null;
  }

  if (!isProduction && typeof window !== "undefined") {
    console.log("Page SEO:", seo);
    console.log("Page content:", newPageContent);
  }

  return (
    <ButterCMSContentContext.Provider value={newPageContent}>
      {seo && (
        <Head>
          <title>{seo.title}</title>
          <meta name="description" content={seo.meta_description} />
          <meta property="og:title" content={seo.opengraph_title} />
          <meta
            property="og:url"
            content={`https://www.autonomy.com${pathname}`}
          />
          <meta property="og:image" content={seo.opengraph_image} />
          <meta property="og:description" content={seo.opengraph_description} />
        </Head>
      )}
      {children}
    </ButterCMSContentContext.Provider>
  );
}

export default ButterCMSContentContext;
